import * as React from "react"
import Helmet from "react-helmet";
import Layout from '../../components/Layout'
import { useTheme } from '@mui/material/styles'
import Box from "@mui/material/Box";
import ProjectHeader, {CoverContent, MyVideo} from "../../components/Common/ProjectHeader";
import {browserTitle, seoDescription, seoTitle, keywords} from "../../data/products/wind";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid/Grid";
import {Box4Title} from "../../components/Common/Base";
import Stack from "@mui/material/Stack";
import { SubTitleEnBox, SubTitleWhiteBox, ProductContact } from "../../components/Common/Base";
import config from "../../config";
import VideoJs from "../../components/Video";
import Typography from "@mui/material/Typography/Typography";


const City3d = (props) => {
  const { location } = props;

  const eleShow = React.useRef();

  const handleNextClick = () => {
    eleShow.current && eleShow.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  };

  return (
    <Layout location={location}>
      <Helmet>
        <meta name="title" content={seoTitle} />
        <meta name="description" content={seoDescription} />
        <meta name="keywords" content={keywords} />
        <title>{browserTitle}</title>
      </Helmet>
      <ProjectHeader
        // backVideo={require('../../images/products/wind/01.mp4').default}
        backImg={require('../../images/products/wind/wind01.jpg').default}
        backVideo={'products/wind/01.m3u8'}
        title={'超分辨率模拟风系统'}
        titleEn={'Weather Simulation Software'}
        titleEn2={'Hyper Resolution Wind Simulation System'}
        subTitle={'天气模拟软件'}
        gridShow
        handleNextClick={handleNextClick}
      />
      {/* box2 */}
      <Container
        ref={eleShow}
        maxWidth="xl"
        sx={{
          pt: {
            xs: 4,
            sm: 8,
            md: 16,
            lg: 24,
          },
          pb: {
            xs: 2,
            sm: 4,
            md: 8,
            lg: 12,
          },
        }}
      >
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
        >
          <Grid
            item
            xs={12}
            md={4}
          >
            <Stack
              direction={{
                xs: 'column',
                lg: 'row'
              }}
              spacing={0}
            >
              <Box4Title
                variant="h4"
                gutterBottom
                sx={{
                  textAlign: {
                    xs: 'center',
                    md: 'left'
                  },
                  whiteSpace: 'nowrap'
                }}
              >
                项目背景
              </Box4Title>
              <Box4Title
                variant="h4"
                gutterBottom
                sx={{
                  textAlign: {
                    xs: 'center',
                    md: 'left'
                  },
                  whiteSpace: 'nowrap'
                }}
              >
                Project Background
              </Box4Title>
            </Stack>
            <SubTitleWhiteBox
              variant="h5"
              gutterBottom
              sx={{
                textAlign: {
                  md: 'left'
                }
              }}
            >
              广东平均每年造成台风直接经济损失46.89亿元，
              是受风灾影响较多的省份之一。
              通过对登陆广东的台风特征及其形成的灾害链进行研究，
              登陆广东的台风具有发生频率高，强度大，范围广，突发性、
              并发性强的特点。由此可见，对于强风的监测预警预报具有迫切需求。
            </SubTitleWhiteBox>
            <SubTitleEnBox gutterBottom variant="h6">
              Guangdong causes an average of 4.689 billion yuan
              of direct economic losses from typhoons each year,
              and is one of the provinces more affected by wind
              disasters. By studying the characteristics of
              typhoons that land in Guangdong and the disaster
              chain they form, typhoons that land in Guangdong
              are characterized by high frequency, high intensity,
              wide range, suddenness and concurrency.
              This shows that there is an urgent need
              for monitoring and warning forecasting of strong winds.
            </SubTitleEnBox>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
          >
            <VideoJs
              imagePath={require('../../images/products/wind/wind02.jpg').default}
              options={{
                autoplay: false,
                controls: false,
                // responsive: true,
                fluid: true,
                preload: 'auto',
                sources: [{
                  src: config.MEDIA_ROOT + 'products/wind/02.m3u8',
                  // src: 'https://media.bixuange.com/static/video/index/02.m3u8',
                  // src: require('../images/index/02.mp4').default,
                  type: 'application/x-mpegURL',
                  // type: 'video/mp4'
                }]
              }}
            />
            {/*<MyVideo*/}
              {/*style={{*/}
                {/*objectFit: 'cover',*/}
              {/*}}*/}
              {/*src={require('../../images/products/wind/02.mp4').default}*/}
              {/*autoPlay="autoPlay"*/}
              {/*loop="loop"*/}
              {/*muted*/}
            {/*>*/}
              {/*您的浏览器不支持 video。*/}
            {/*</MyVideo>*/}
          </Grid>
        </Grid>
      </Container>
      {/* box3 */}
      <Container
        maxWidth="xl"
        sx={{
          pt: {
            xs: 2,
            sm: 4,
            md: 8,
            lg: 12,
          },
          pb: {
            xs: 4,
            sm: 8,
            md: 16,
            lg: 24,
          },
        }}
      >
        <Stack
          direction={{
            xs: 'column',
          }}
          sx={{
            pb: {
              xs: 2,
              sm: 4,
              md: 8,
              lg: 12,
            },
          }}
          spacing={0}
        >
          <Box4Title
            variant="h4"
            gutterBottom
            sx={{
              textAlign: {
                xs: 'center',
              }
            }}
          >
            项目概况
          </Box4Title>
          <Box4Title
            variant="h4"
            gutterBottom
            sx={{
              textAlign: {
                xs: 'center',
              }
            }}
          >
            Service Overview
          </Box4Title>
        </Stack>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            md={7}
          >
            <img alt="" width="100%" src={require('../../images/products/wind/03.jpg').default} />
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
          >
            <SubTitleWhiteBox
              variant="h5"
              gutterBottom
            >
              三维风场模拟为了适应城市需要, 可以满足超大城市微环境的模拟仿真。
            </SubTitleWhiteBox>
            <SubTitleEnBox gutterBottom variant="h6">
              Three-dimensional wind field simulation can meet the simulation
              of mega-city micro-environment in order to adapt to urban needs.
            </SubTitleEnBox>
            <br />
            <SubTitleWhiteBox
              variant="h5"
              gutterBottom
              sx={{
                fontWeight: '200'
              }}
            >
              基于城市复杂下垫面环境的大风三维数值模拟仿真系统通过高精度修正地理模型，
              模拟复杂下垫面环境下城市强风的数值，从而判断出城市容易出现强风或是负压的风险点，
              并与测试验证模拟结果与自动站数据之间的误差，为各类户外生产活动提供精细化强风预警服务。
            </SubTitleWhiteBox>
            <SubTitleEnBox gutterBottom variant="h6">
              Based on the complex subsurface environment of the city,
              the 3D numerical simulation system of high wind simulates the value of
              strong wind in the complex subsurface environment, so as to determine
              the risk point of strong wind or negative pressure in the city,
              and verify the error between the simulation result and the automatic
              station data with the test, and provide fine wind warning service
              for various outdoor production activities.
            </SubTitleEnBox>
          </Grid>
        </Grid>
      </Container>
      {/* box4 */}
      <Box
        sx={{
          backgroundColor: '#0e3240',
          position: 'relative',
          pb: {
            xs: 2,
            sm: 4,
            md: 8,
            lg: 12,
          },
          pt: {
            xs: 2,
            sm: 4,
            md: 8,
            lg: 12,
          }
        }}
      >
        <CoverContent>
          <Container
            maxWidth="xl"
            style={{
              position: 'relative',
              zIndex: 5,
              overflow: 'hidden',
              pl: 0,
              pr: 0,
            }}
          >
            <Box
              sx={{
                height: {
                  xs: '13vw',
                  md: '3.5vw',
                  xl: '50px',
                },
              }}
            />
            <Box
              sx={{
                width: {
                  xs: '150vw',
                  md: '100%'
                },
                height: {
                  xs: '138vw',
                  md: 'auto'
                }
              }}
            >
              <VideoJs
                imagePath={require('../../images/products/wind/wind-03-2.jpg').default}
                options={{
                  autoplay: true,
                  controls: false,
                  // responsive: true,
                  fluid: true,
                  preload: 'auto',
                  sources: [{
                    src: config.MEDIA_ROOT + 'products/wind/03.m3u8',
                    // src: 'https://media.bixuange.com/static/video/index/02.m3u8',
                    // src: require('../images/index/02.mp4').default,
                    type: 'application/x-mpegURL',
                    // type: 'video/mp4'
                  }]
                }}
              />
            </Box>
          </Container>
        </CoverContent>
        <Box
          sx={{
            position: 'relative',
            top: 0,
            zIndex: 6,
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
          }}
        >
          <Stack
            direction={{
              xs: 'column',
            }}
            spacing={0}
            sx={{
              height: {
                xs: '12vw',
                md: '10vw',
                xl: '160px',
              },
            }}
          >
            <Box4Title
              variant="h4"
              gutterBottom
              sx={{
                textAlign: {
                  xs: 'center',
                },
                color: '#fff',
              }}
            >
              模拟风模型
            </Box4Title>
            <Box4Title
              variant="h4"
              gutterBottom
              sx={{
                textAlign: {
                  xs: 'center',
                },
                color: '#fff',
              }}
            >
              Simulated Wind Model
            </Box4Title>
          </Stack>
          <Container
            maxWidth="xl"
          >
            <Box
              sx={{
                width: '40%',
                ml: '60%',
                mb: {
                  xs: 0,
                  lg: 2,
                },
                display: {
                  xs: 'none',
                  md: 'block',
                }
              }}
            >
              <SubTitleWhiteBox
                variant="h5"
                gutterBottom
                sx={{
                  fontWeight: '100'
                }}
              >
                本系统针对强风对整个城市进行三维数值模拟仿真，基于GIS的数据
                和城市三维建模技术的CFD模拟可以做到超精细化的分析，
                实现对米级格点的大风仿真；通过对比自动站的实况监测数据，
                为不同高度的风速风向分析并已经业务应用，为城市护航。
              </SubTitleWhiteBox>
              <SubTitleEnBox gutterBottom variant="h6">
                This system for strong winds for the whole city
                to carry out three-dimensional numerical simulation,
                CFD simulation based on GIS data and city three-dimensional modeling technology
                can do ultra-fine analysis, archieved the simulation of
                the metre-level grid point of the wind;
                by comparing the live monitoring data of the automatic station,
                for different heights of wind speed and wind direction analysis
                and has been business applications, for the city escort.
              </SubTitleEnBox>
            </Box>
          </Container>
          <Container
            maxWidth="xl"
            sx={{
              flex: 1,
            }}
          >
            <Stack
              sx={{
                height: {
                  xs: '150vw',
                  sm: '130vw',
                  md: 'auto',
                },
                width: {
                  xs: '80%',
                  md: '40%',
                },
                ml: {
                  xs: '10%',
                  md: '60%'
                },
              }}
              spacing={{
                xs: 0,
                lg: 4
              }}
              justifyContent={{
                xs: "space-between",
                md: "flex-start",
              }}
            >
              <Grid
                sx={{mb: 4}}
                container
                spacing={2}
                justifyContent="flex-end"
              >
                <Grid
                  item
                  xs={5}
                >
                  <SubTitleWhiteBox
                    variant="h5"
                    gutterBottom
                    sx={{
                      color: '#1BAFBF'
                    }}
                  >
                    子区域模拟
                    <br />
                    Sub-area simulation
                  </SubTitleWhiteBox>
                </Grid>
                <Grid
                  item
                  xs={7}
                >
                  <SubTitleWhiteBox
                    variant="h5"
                    gutterBottom
                    sx={{
                      fontWeight: '100',
                      fontSize: {
                        xs: '12px',
                        sm: '2.5vw',
                        lg: '24px'
                      }
                    }}
                  >
                    ~模拟范围1km2, 0.8亿网格网格分辨率2.5m (超图片分辨率)
                  </SubTitleWhiteBox>
                  <SubTitleEnBox gutterBottom variant="h6">
                    ~80 Million meshs Grid Simulation
                  </SubTitleEnBox>
                </Grid>
              </Grid>
              <Grid
                sx={{mb: 4}}
                container
                spacing={2}
                justifyContent="flex-end"
              >
                <Grid
                  item
                  xs={5}
                >
                  <SubTitleWhiteBox
                    variant="h5"
                    gutterBottom
                    sx={{
                      color: '#1BAFBF'
                    }}
                  >
                    全市模拟
                    <br />
                    Whole City Simulation
                  </SubTitleWhiteBox>
                </Grid>
                <Grid
                  item
                  xs={7}
                >
                  <SubTitleWhiteBox
                    variant="h5"
                    gutterBottom
                    sx={{
                      fontWeight: '100',
                      fontSize: {
                        xs: '12px',
                        sm: '2.5vw',
                        lg: '24px'
                      }
                    }}
                  >
                    ~模拟范围2000km2,1.25亿网格网格 (超图片分辨率)
                  </SubTitleWhiteBox>
                  <SubTitleEnBox gutterBottom variant="h6">
                    ~125 million meshs Grid resolution simulation
                  </SubTitleEnBox>
                </Grid>
              </Grid>
            </Stack>
            <Box
              sx={{
                display: {
                  xs: 'block',
                  md: 'none',
                },
                pt: {
                  xs: 2,
                },
                pb: {
                  xs: 2,
                },
              }}
            >
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  color: '#ffffff',
                  fontWeight: '100',
                  fontSize: {
                    xs: '14px',
                    lg: '1.8vw',
                    xl: '18px',

                  }
                }}
              >
                本系统针对强风对整个城市进行三维数值模拟仿真，基于GIS的数据
                和城市三维建模技术的CFD模拟可以做到超精细化的分析，
                实现对米级格点的大风仿真；通过对比自动站的实况监测数据，
                为不同高度的风速风向分析并已经业务应用，为城市护航。
              </Typography>
              <Typography
                gutterBottom
                variant="h6"
                sx={{
                  fontSize: {
                    xs: '12px',
                    lg: '1.6vw',
                    xl: '16px',
                  }
                }}
              >
                This system for strong winds for the whole city
                to carry out three-dimensional numerical simulation,
                CFD simulation based on GIS data and city three-dimensional modeling technology
                can do ultra-fine analysis, archieved the simulation of
                the metre-level grid point of the wind;
                by comparing the live monitoring data of the automatic station,
                for different heights of wind speed and wind direction analysis
                and has been business applications, for the city escort.
              </Typography>
            </Box>
            <Box
              sx={{
                height: {
                  xs: 0,
                  lg: '350px',
                }
              }}
            />
          </Container>
        </Box>
      </Box>
      {/* box5 */}
      <Box>
        <Container
          maxWidth="xl"
          sx={{
            pb: {
              xs: 2,
              sm: 4,
              md: 8,
              lg: 12,
            },
            pt: {
              xs: 2,
              sm: 4,
              md: 8,
              lg: 12,
            }
          }}
        >
          <Stack
            direction={{
              xs: 'column',
            }}
            spacing={0}
          >
            <Box4Title
              variant="h4"
              gutterBottom
              sx={{
                textAlign: {
                  xs: 'center',
                }
              }}
            >
              模拟建筑物风压收敛
            </Box4Title>
            <Box4Title
              variant="h4"
              gutterBottom
              sx={{
                textAlign: {
                  xs: 'center',
                }
              }}
            >
              Wind Force Apply on Buildings Convergence
            </Box4Title>
          </Stack>
          <VideoJs
            imagePath={require('../../images/products/wind/wind04.jpg').default}
            options={{
              autoplay: true,
              controls: false,
              // responsive: true,
              fluid: true,
              preload: 'auto',
              sources: [{
                src: config.MEDIA_ROOT + 'products/wind/04.m3u8',
                // src: 'https://media.bixuange.com/static/video/index/02.m3u8',
                // src: require('../images/index/02.mp4').default,
                type: 'application/x-mpegURL',
                // type: 'video/mp4'
              }]
            }}
          />
          <Box
            sx={{
              position: 'relative',
              zIndex: 10,
              marginTop: '-5vw',
            }}
          >
            <SubTitleWhiteBox
              variant="h5"
              gutterBottom
              sx={{
                fontWeight: '100',
                textAlign: {
                  xs: 'center',
                },
              }}
            >
              风压在建筑物与地表的瞬时计算结果
            </SubTitleWhiteBox>
            <SubTitleEnBox
              gutterBottom
              variant="h6"
              sx={{
                textAlign: {
                  xs: 'center',
                }
              }}
            >
              Transient (time dependent)  pressure distribution on ground and buildings
            </SubTitleEnBox>
          </Box>
        </Container>
      </Box>
      {/* box6 */}
      <Box
        sx={{
          pb: {
            xs: 2,
            sm: 4,
            md: 8,
            lg: 12,
          },
          pt: {
            xs: 2,
            sm: 4,
            md: 8,
            lg: 12,
          }
        }}
      >
        <Box
          sx={{
            position: 'relative',
          }}
        >
          <Container
            maxWidth="xl"
            sx={{
              pt: {
                xs: '15vw',
                md: 3,
                lg: 6,
              },
              pb: {
                xs: '10vw',
                md: 2,
                lg: 4,
              }
            }}
          >
            <Box
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                container
                spacing={2}
                alignItems={'flex-end'}
                sx={{
                  mb: 1
                }}
              >
                <Grid
                  item
                  xs={5}
                >
                  <img alt="" width="100%" src={require('../../images/products/wind/05.jpg').default} />
                </Grid>
                <Grid
                  item
                  xs={7}
                >
                  <img alt="" width="100%" src={require('../../images/products/wind/06.jpg').default} />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                alignItems={'flex-start'}
              >
                <Grid
                  item
                  xs={5}
                >
                  <img alt="" width="100%" src={require('../../images/products/wind/07.jpg').default} />
                </Grid>
                <Grid
                  item
                  xs={7}
                >
                  <img alt="" width="100%" src={require('../../images/products/wind/08.jpg').default} />
                </Grid>
              </Grid>
            </Box>
          </Container>
          <CoverContent>
            <Container
              maxWidth="xl"
              style={{height: '100%'}}
            >
              <Stack
                sx={{
                  height: '100%',
                  width: {
                    xs: '100%',
                    md: '40%',
                  },
                }}
                spacing={{
                  xs: 0,
                }}
                justifyContent={{
                  xs: "space-between",
                }}
              >
                <Box>
                  <Box4Title
                    variant="h4"
                    gutterBottom
                    sx={{
                      textAlign: {
                        xs: 'center',
                        md: "left",
                      },
                    }}
                  >
                    不同高度下的风
                  </Box4Title>
                  <Box4Title
                    variant="h4"
                    gutterBottom
                    sx={{
                      textAlign: {
                        xs: 'center',
                        md: "left",
                      },
                      fontSize: {
                        md: '2vw',
                        lg: '24px',
                      }
                    }}
                  >
                    Results of different level wind
                  </Box4Title>
                </Box>
                <Box>
                  <SubTitleWhiteBox
                    variant="h5"
                    gutterBottom
                    sx={{
                      fontWeight: '100',
                    }}
                  >
                    2米至1000米的瞬时风变化
                  </SubTitleWhiteBox>
                  <SubTitleEnBox
                    gutterBottom
                    variant="h6"
                  >
                    Results sweep between ground level and +100m height
                  </SubTitleEnBox>
                </Box>
              </Stack>
            </Container>
          </CoverContent>
        </Box>
      </Box>
      {/* box7 */}
      <Box
        sx={{
          pb: {
            xs: 2,
            sm: 4,
            md: 8,
            lg: 12,
          },
          pt: {
            xs: 2,
            sm: 4,
            md: 8,
            lg: 12,
          }
        }}
      >
        <Box>
          <Box4Title
            variant="h4"
            gutterBottom
            sx={{
              textAlign: {
                xs: 'center',
              },
            }}
          >
            不同的渲染风格
          </Box4Title>
          <Box4Title
            variant="h4"
            gutterBottom
            sx={{
              textAlign: {
                xs: 'center',
              },
              pb: {
                xs: 2,
                md: 4,
                lg: 6,
              }
            }}
          >
            Different Rendering Styles
          </Box4Title>
        </Box>
        <Grid
          container
          spacing={2}
          alignItems={'center'}
          sx={{
            mb: 1
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
          >
            <img alt="" width="100%" src={require('../../images/products/wind/09.jpg').default} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <img alt="" width="100%" src={require('../../images/products/wind/091.jpg').default} />
          </Grid>
        </Grid>
      </Box>
      {/* box8 */}
      <Box
        sx={{
          pb: {
            xs: 2,
            sm: 4,
            md: 8,
            lg: 12,
          },
          pt: {
            xs: 2,
            sm: 4,
            md: 8,
            lg: 12,
          }
        }}
      >
        <Box>
          <Box4Title
            variant="h4"
            gutterBottom
            sx={{
              textAlign: {
                xs: 'center',
              },
            }}
          >
            定制化模拟风系统
          </Box4Title>
          <Box4Title
            variant="h4"
            gutterBottom
            sx={{
              textAlign: {
                xs: 'center',
              },
              pb: {
                xs: 2,
                md: 4,
                lg: 6,
              }
            }}
          >
            Customized Smulated Wind System
          </Box4Title>
        </Box>
        <Grid
          container
          spacing={2}
          alignItems={'center'}
          sx={{
            mb: 1
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
          >
            <img alt="" width="100%" src={require('../../images/products/wind/10.jpg').default} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <img alt="" width="100%" src={require('../../images/products/wind/11.jpg').default} />
          </Grid>
        </Grid>
      </Box>
      {/* box9 */}
      <ProductContact />
    </Layout>
  )
}


export default City3d;
